/* Reset and Basic Styles */
*,
:after,
:before {
    box-sizing: inherit;
}

body,
h1,
h2,
h3,
hr,
html,
li,
p,
ul,
button,
select {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3 {
    font-size: 100%;
    font-weight: 400;
}

ul {
    list-style: none;
}

html {
    box-sizing: border-box;
    background-color: #fff;
    font-size: 16px;
    min-width: 500px; /* Minimum screen width */
    overflow-x: hidden;
    overflow-y: scroll;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
}

body,
button,
input,
select,
textarea {
    font-family: Ubuntu, Arial, Helvetica, Verdana, sans-serif;
    color: #4a4a4a;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    background-color: #fafafa;
}

a {
    color: #FF7144;
    text-decoration: none;
}

span,
strong {
    font-style: inherit;
    font-weight: inherit;
}

strong {
    color: inherit;
    font-weight: 700;
}

/* Typography */
hr {
    background-color: #ccc;
    border: none;
    display: block;
    height: 1px;
    margin: 1.5rem auto;
}

input[type=checkbox] {
    vertical-align: baseline;
}

.has-text-centered {
    text-align: center !important;
}

/* Responsive Text Alignment */
@media print,
screen and (min-width:769px) {
    .has-text-centered-tablet {
        text-align: center !important;
    }
}

@media screen and (min-width:1024px) {
    .has-text-centered-desktop {
        text-align: center !important;
    }
}

/* Accessibility */
.is-hidden {
    display: none !important;
}

.is-sr-only {
    border: none !important;
    clip: rect(0, 0, 0, 0) !important;
    height: .01em !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
    width: .01em !important;
}

/* Responsive Visibility */
@media screen and (max-width:1023px) {
    .is-hidden-touch {
        display: none !important;
    }
}

@media screen and (min-width:1024px) {
    .is-hidden-desktop {
        display: none !important;
    }
}

/* Button Styles */
.button {
    background-color: #fff;
    border-color: #dbdbdb;
    border-width: 1px;
    color: inherit;
    justify-content: center;
    padding: calc(.5em - 1px) 1em;
    text-align: center;
    white-space: nowrap;
    font-weight: 700;
}

.button .icon {
    height: 1.5em;
    width: 1.5em;
}

.button .icon:first-child:not(:last-child) {
    margin-left: calc(-.5em - 1px);
    margin-right: .25em;
}

.button .icon:first-child:last-child {
    margin-left: calc(-.5em - 1px);
    margin-right: calc(-.5em - 1px);
}

.button.is-text {
    background-color: transparent;
    border-color: transparent;
    color: #FF7B52;
    text-decoration: underline;
    height: auto;
    padding: 0;
    margin: 0;
    line-height: inherit;
    font-weight: inherit;
    font-size: inherit;
    vertical-align: inherit;
    border-width: 0;
}

.button.is-dark {
    background-color: #363636;
    border-color: transparent;
    color: #eee;
}

.button.is-action {
    background-color: #FF9E80;
    border-color: transparent;
    color: #fff;
}

.button.is-support {
    background-color: #f1c40f;
    border-color: transparent;
    color: #555;
}

.button.is-small {
    border-radius: 2px;
    font-size: .75rem;
}

.button.is-medium {
    font-size: 1rem;
}

.button.is-transparent {
    background-color: transparent;
    border-color: transparent;
    color: #4a4a4a;
    font-weight: inherit;
}

.buttons {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.buttons .button {
    margin-bottom: .5rem;
}

.buttons .button:not(:last-child):not(.is-fullwidth) {
    margin-right: .5rem;
}

.buttons:last-child {
    margin-bottom: -.5rem;
}

.buttons:not(:last-child) {
    margin-bottom: 1rem;
}

.buttons.has-addons .button:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.buttons.has-addons .button:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    margin-right: -1px;
}

.buttons.has-addons .button:last-child {
    margin-right: 0;
}

/* Container */
.container {
    flex-grow: 1;
    margin: 0 auto;
    position: relative;
    width: auto;
}

@media screen and (min-width:1024px) {
    .container {
        max-width: 960px;
    }
}

@media screen and (min-width:1216px) {
    .container {
        max-width: 1152px;
    }
}

/* Content */
.content p:not(:last-child) {
    margin-bottom: 1em;
}

/* Icon */
.icon {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    height: 1.5rem;
    width: 1.5rem;
}

.icon.is-large {
    height: 3rem;
    width: 3rem;
}

/* Notification */
.notification {
    background-color: #fafafa;
    border-radius: 4px;
    padding: 1.25rem 2.5rem 1.25rem 1.5rem;
    position: relative;
}

.notification .title {
    color: currentColor;
}

/* Progress */
.progress {
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    border-radius: 290486px;
    display: block;
    height: 1rem;
    overflow: hidden;
    padding: 0;
    width: 100%;
}

.progress::-webkit-progress-bar {
    background-color: #ededed;
}

.progress::-webkit-progress-value {
    background-color: #FF9E80;
}

.progress::-moz-progress-bar {
    background-color: #FF9E80;
}

.progress::-ms-fill {
    background-color: #FF9E80;
    border: none;
}

/* Title */
.title {
    word-break: break-word;
    color: inherit;
    font-size: 2.5em;
    font-weight: 600;
    line-height: 1.125;
    text-transform: uppercase;
    text-align: center;
}

.title span {
    font-weight: inherit;
}

/* Input and Select */
.input,
.select select,
.input::-moz-placeholder,
.select select::-moz-placeholder,
.input::-webkit-input-placeholder,
.select select::-webkit-input-placeholder,
.input:-moz-placeholder,
.select select:-moz-placeholder,
.input:-ms-input-placeholder,
.select select:-ms-input-placeholder {
    color: #aaa;
}

.input {
    box-shadow: none;
    max-width: 400px;
    width: 100%;
    margin: 2px;
}

.input.half {
    max-width: 196px;
    width: 100%;
    height: 40px;
    margin: 2px;
}

.input .checkbox {
    display: inline-block;
    line-height: 1.25;
    position: relative;
}

.select {
    display: inline-block;
    max-width: 100%;
    position: relative;
    vertical-align: top;
}

.select:not(.is-multiple) {
    height: 2.5em;
}

.select:not(.is-multiple):not(.is-loading):after {
    border: 3px solid transparent;
    border-radius: 2px;
    border-right: 0;
    border-top: 0;
    content: " ";
    display: block;
    height: .625em;
    margin-top: -.4375em;
    position: absolute;
    top: 50%;
    transform: rotate(-45deg);
    transform-origin: center;
    width: .625em;
    border-color: #194f72;
    right: 1.125em;
    z-index: 4;
}

.select select {
    display: block;
    font-size: 1em;
    max-width: 100%;
    outline: 0;
    height: 2.75em; /* Adjust height */
    padding: 0.5em; /* Add padding */
}

.select select::-ms-expand {
    display: none;
}

.select select:not([multiple]) {
    padding-right: 2.5em;
}

.select.is-fullwidth,
.select.is-fullwidth select {
    width: 100%;
    max-width: 400px;
}

.select:not(.is-multiple):not(.is-loading):after {
    content: none; /* Remove the additional arrow */
}

/* Label */
.label {
    color: inherit;
    display: block;
    font-size: 1rem;
    font-weight: 700;
    font-family: monospace;
}

.label:not(:last-child) {
    margin-bottom: .5em;
}

/* Field */
.field:not(:last-child) {
    margin-bottom: .75rem;
}

.field.is-grouped {
    display: flex;
    justify-content: center;
}

.field.is-grouped>.control {
    flex-shrink: 0;
}

.field.is-grouped>.control:not(:last-child) {
    margin-bottom: 0;
    margin-right: .75rem;
}

.field.is-grouped.is-grouped-centered {
    justify-content: center;
}

/* Control */
.control {
    box-sizing: border-box;
    clear: both;
    font-size: 1rem;
    position: relative;
    text-align: left;
    justify-content: center;
    display: flex;
}

/* Modal */
.modal {
    align-items: center;
    display: none;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    position: fixed;
    z-index: 40;
}

.modal-background {
    background-color: rgba(10, 10, 10, .86);
}

.modal-content {
    margin: 0 20px;
    max-height: calc(100vh - 160px);
    overflow: auto;
    position: relative;
    width: 100%;
}

@media print,
screen and (min-width:769px) {
    .modal-content {
        margin: 0 auto;
        max-height: calc(100vh - 40px);
        width: 640px;
    }
}

/* Navbar */
.navbar {
    justify-content: space-between;
    align-items: center;
    background-color: #f8f8f8;
    min-height: 3.125rem;
    position: relative;
    z-index: 30;
    border-bottom: 1px solid #e7e7e7;
}

.navbar>.container {
    align-items: stretch;
    display: flex;
    min-height: 3.125rem;
    width: 100%;
    max-width: 1140px;
}

.navbar.is-fixed-top {
    left: 0;
    position: fixed;
    right: 0;
    z-index: 30;
    top: 0;
}

.navbar-brand {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    min-height: 3.125rem;
}

.navbar-burger {
    color: #4a4a4a;
    display: block;
    height: 3.125rem;
    position: relative;
    width: 3.125rem;
    margin-left: auto;
}

.navbar-burger span {
    background-color: currentColor;
    display: block;
    height: 1px;
    left: calc(50% - 8px);
    position: absolute;
    transform-origin: center;
    width: 16px;
    height: .05em;
}

.navbar-burger span:first-child {
    top: calc(50% - 6px);
}

.navbar-burger span:nth-child(2) {
    top: calc(50% - 1px);
}

.navbar-burger span:nth-child(3) {
    top: calc(50% + 4px);
}

.navbar-menu {
    display: none;
}

.navbar-item,
.navbar-link {
    color: #4a4a4a;
    display: block;
    line-height: 1.5;
    padding: .5rem .75rem;
    position: relative;
}

.navbar-item .icon:only-child,
.navbar-link .icon:only-child {
    margin-left: -.25rem;
    margin-right: -.25rem;
}

.navbar-item {
    display: block;
    flex-grow: 0;
    flex-shrink: 0;
}

.navbar-item.has-dropdown {
    padding: 0;
}

.navbar-dropdown {
    font-size: .875rem;
    padding-bottom: .5rem;
    padding-top: .5rem;
}

.navbar-dropdown .navbar-item {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.navbar-divider {
    background-color: #fafafa;
    border: none;
    display: none;
    height: 2px;
    margin: .5rem 0;
    max-width: none;
}

/* Responsive Navbar */
@media screen and (max-width:1023px) {
    .navbar>.container {
        display: block;
    }

    .navbar-brand .navbar-item {
        align-items: center;
        display: flex;
    }

    .navbar-link:after {
        display: none;
    }

    .navbar-menu {
        background-color: #f8f8f8;
        box-shadow: 0 8px 16px rgba(10, 10, 10, .1);
        padding: .5rem 0;
    }

    .navbar.is-fixed-top .navbar-menu {
        -webkit-overflow-scrolling: touch;
        max-height: calc(100vh - 3.125rem);
        overflow: auto;
        box-shadow: none;
    }
    .navbar-brand {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
  }

  .navbar-end {
      display: none;
  }

  .navbar-brand .button.is-action {
      display: flex;
      align-items: center;
      margin-left: auto;
  }
}

@media screen and (min-width:1024px) {
    .navbar,
    .navbar-end,
    .navbar-menu,
    .navbar-start {
        align-items: stretch;
        display: flex;
        margin-left: auto;
    }

    .navbar {
        min-height: 3.125rem;
    }

    .navbar.is-spaced {
        padding: .625rem 1.5rem;
    }

    .navbar.is-spaced .navbar-end,
    .navbar.is-spaced .navbar-start {
        align-items: center;
    }

    .navbar.is-spaced .navbar-link,
    .navbar.is-spaced a.navbar-item {
        border-radius: 4px;
    }

    .navbar-burger {
        display: none;
    }

    .navbar-item,
    .navbar-link {
        align-items: center;
        display: flex;
    }

    .navbar-item {
        display: flex;
    }

    .navbar-item.has-dropdown {
        align-items: stretch;
    }

    .navbar-menu {
        flex-grow: 1;
        flex-shrink: 0;
    }

    .navbar-start {
        justify-content: flex-start;
        margin-right: auto;
    }

    .navbar-end {
        display: flex;
        align-items: center;
    }

    .navbar-dropdown {
        background-color: #fff;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border-top: 2px solid #dbdbdb;
        box-shadow: 0 8px 8px rgba(10, 10, 10, .1);
        display: none;
        font-size: .875rem;
        left: 0;
        min-width: 100%;
        position: absolute;
        top: 100%;
        z-index: 20;
    }

    .navbar-dropdown .navbar-item {
        padding: .375rem 1rem;
        white-space: nowrap;
    }

    .navbar-dropdown a.navbar-item {
        padding-right: 3rem;
    }

    .navbar-dropdown.is-boxed,
    .navbar.is-spaced .navbar-dropdown {
        border-radius: 6px;
        border-top: none;
        box-shadow: 0 8px 8px rgba(10, 10, 10, .1), 0 0 0 1px rgba(10, 10, 10, .1);
        display: block;
        opacity: 0;
        top: calc(100% + -4px);
        transform: translateY(-5px);
    }

    .navbar-divider {
        display: block;
    }

    .navbar>.container .navbar-brand {
        margin-left: -.75rem;
    }

    .navbar>.container .navbar-menu {
        margin-right: -.75rem;
    }
}

/* Columns */
.column {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 1rem;
}

.columns.is-mobile>.column.is-narrow {
    flex: none;
}

.columns.is-mobile>.column.is-12 {
    flex: none;
    width: 100%;
}

@media screen and (max-width:768px) {
    .column.is-12-mobile {
        flex: none;
        width: 100%;
    }

    .navbar-end {
        margin-left: auto;
    }
}

@media print,
screen and (min-width:769px) {
    .column.is-narrow {
        flex: none;
    }

    .column.is-10-tablet {
        flex: none;
        width: 83.33333%;
    }

    .column.is-12 {
        flex: none;
        width: 100%;
    }
}

@media screen and (max-width:1023px) {
    .column.is-12-touch {
        flex: none;
        width: 100%;
    }
}

@media screen and (min-width:1024px) {
    .column.is-2-desktop {
        flex: none;
        width: 15%;
    }

    .column.is-8-desktop {
        flex: none;
        width: 42.5%;
    }
}

@media screen and (min-width:1216px) {
    .column.is-6-widescreen {
        flex: none;
        width: 50%;
    }
}

.columns {
    margin-left: -1rem;
    margin-right: -1rem;
    margin-top: -1rem;
}

.columns:last-child {
    margin-bottom: -1rem;
}

.columns:not(:last-child) {
    margin-bottom: .5rem;
}

.columns.is-centered {
    justify-content: center;
}

.columns.is-mobile {
    display: flex;
}

.columns.is-multiline {
    flex-wrap: wrap;
}

.columns.is-vcentered {
    align-items: center;
}

@media print,
screen and (min-width:769px) {
    .columns:not(.is-desktop) {
        display: flex;
    }
}

/* Section */
.section {
    padding: 3.5rem 1.5rem 4.5rem;
    display: block;
}

.section.is-primary {
    background-color: #3d474d;
    color: #fff;
}

.section:first-of-type {
    padding-top: 6.75rem;
}

@media screen and (min-width:1024px) {
    .section:first-of-type {
        padding-top: 7.875rem;
    }
}

.section.results {
    background-color: #222;
    padding: 0;
}

/* CodeMirror */
.CodeMirror {
    font-family: monospace;
    height: 300px;
    color: #000;
    direction: ltr;
    position: relative;
    overflow: hidden;
    background: #fff;
    min-height: 10rem;
    height: 20rem;
}

.CodeMirror {
    min-height: 10rem;
    height: 20rem;
}

/* Notification */
.notification {
    margin-bottom: 0 !important;
    width: 100% !important;
    justify-content: center;
    text-align: center;
    border-radius: 0;
}

@media screen and (min-width:1024px) {
    .notification {
        width: auto !important;
        margin: 0 1em 1em !important;
        border-radius: 4px;
    }
}

/* Content */
.content {
    font-size: 1.125em;
    line-height: 1.65;
    margin-bottom: 1rem;
}

.content p {
    font-size: 1.125em;
    line-height: 1.65;
}

/* Media */
.media {
    display: none;
}

@media print,
screen and (min-width:769px) {
    .media {
        display: block;
    }
}

/* Partner */
.partner {
    overflow: hidden;
}

@media screen and (min-width:1024px) {
    .partner {
        min-height: 90px;
    }
}

/* Blocker */
.blocker {
    display: none;
}

.blocker p {
    margin: 0 15px 15px;
    color: #ccc;
}

.blocker .button {
    margin-left: .5rem;
    margin-right: .5rem;
}

/* Input */
.input,
.select select,
.select select option {
    font-weight: 300;
}

#input .checkbox input#jsonfix {
    left: -3000px;
    position: absolute;
}

#input .checkbox input#jsonfix+.icon {
    margin-left: .25rem;
    top: 2px;
    position: relative;
}

#input .checkbox input#jsonfix+.icon svg:first-child {
    font-size: 1.3333em;
}

#input .checkbox input#jsonfix+.icon svg:last-child {
    position: absolute;
    color: #FF9E80;
    font-size: .85rem;
    width: 0.8em;
    top: .3677em;
    left: .3677em;
}

#input .checkbox input#jsonfix+.icon:after {
    content: "";
    display: block;
    position: absolute;
    background-color: #fff;
    top: 4px;
    right: 4px;
    bottom: 4px;
    left: 4px;
    border-radius: 3px;
}

#input .checkbox input#jsonfix:checked+.icon:after {
    left: 100%;
}

#input .buttons.is-vertical {
    position: absolute;
    bottom: 3px;
    right: 20px;
}

#input .buttons.is-vertical .button {
    color: #FF7B52;
}

#input .hint .button {
    color: inherit;
}

#input .hint .button:after {
    content: "";
    position: absolute;
    display: block;
    border-bottom: 1px dotted #fff;
    left: 0;
    bottom: 1px;
    right: 0;
}

#input .options .field.is-grouped {
    justify-content: center;
}

#input .options .field.is-grouped>.control:not(:last-child) {
    margin-right: .25rem;
}

#input .container {
    max-width: 1140px;
}

#input .buttons.is-vertical [aria-label][data-balloon-pos]:before {
    margin-right: -5px;
}

#input .buttons.is-vertical [aria-label][data-balloon-pos]:after {
    margin-right: 5px;
}

#input .options {
    padding-top: 0;
}

@media screen and (min-width:1024px) {
    #input .options {
        padding-top: 1rem;
    }
}

#input .control .dropzone,
#input .control .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background-color: hsla(0, 0%, 99.6%, .9);
    border-radius: 4px;
    flex-direction: column;
    display: none;
    font-size: 1.125rem;
    color: #4a4a4a;
}

#input .control .dropzone .icon,
#input .control .loading .icon {
    height: 5rem;
    width: 5rem;
    margin-bottom: 1rem;
}

#input .control .dropzone .icon svg,
#input .control .loading .icon svg {
    font-size: 5rem;
}

#input .control .loading .progress {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px 4px 0 0;
    height: 6px;
    background-color: transparent;
    margin-bottom: 0;
}

#input .control .loading .icon {
    color: #FF9E80;
}

/* Editor */
.editor {
    display: inline-flex;
    gap: 10px;
    font-family: monospace;
    line-height: 21px;
    background: #282a3a;
    border-radius: 2px;
    padding: 20px 10px;
    height: 400px;
    overflow-y: auto;
}

.editor.label {
    display: inline-flex;
    font-family: monospace;
    line-height: 21px;
    background: none;
    border-radius: none;
    padding: 5px 10px;
    height: auto;
    overflow-y: auto;
}

.line-numbers {
    width: 20px;
    text-align: right;
   
}

.line-numbers span {
    counter-increment: linenumber;
}

.line-numbers span::before {
    content: counter(linenumber);
    display: block;
    color: #506882;
}

textarea {
    flex: 1;
    line-height: 21px;
    padding: 0;
    border: 0;
    background: #282a3a;
    color: #FFF;
    min-width: 410px;
    outline: none;
    resize: none;
    overflow-y: scroll;
}

/* Result */
.result .buttons .button {
    margin-bottom: 0;
}

.result .dataholder {
    position: relative;
}

.result .validation {
    color: #fff;
    font-weight: 900;
    background-color: #c0392b;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.25rem;
    border-radius: 4px;
    margin-bottom: 1rem;
}

.result .validation.is-valid {
    background-color: #27ae60;
}

.result .description {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.result .description input {
    display: none;
    padding: 0 5px;
    height: calc(1.75rem - 4px);
    border-width: 0;
}

.result h3 {
    font-size: 3em;
    font-weight: 900;
    line-height: 1;
    color: #fff;
}

.result .url {
    color: #999;
    font-style: italic;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.result .buttons {
    justify-self: right;
    align-self: center;
    margin-bottom: 0;
}

.result .bottom {
    margin-top: 1rem;
}

.result .dataholder .buttons {
    position: absolute;
    bottom: 10px;
    right: 29px;
    z-index: 10;
}

.results .label {
    color: #fff;
}

.results .media {
    padding: 1.25rem 0;
    border-bottom: 1px solid #111;
}

.results .result {
    padding: 20px;
    border-top: 1px solid #333;
    border-bottom: 1px solid #111;
}

.results .messages {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid transparent;
    padding: calc(.75em - 1px);
    max-height: 10em;
    overflow-x: auto;
    overflow-y: scroll;
}

.results .messages .message {
    white-space: nowrap;
}

.results .messages .message .icon {
    color: #FF9E80;
}

.results .messages .message strong {
    width: 5.6em;
    display: inline-block;
}

.results .messages .message em {
    font-size: .8em;
    color: #999;
}

.results .messages .message.is-error .icon {
    color: #c0392b;
}

.results .messages .message.is-warning .icon {
    color: #f1c40f;
}

/* Input Specific Styles */
#input .control .dropzone,
#input .control .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background-color: hsla(0, 0%, 99.6%, .9);
    border-radius: 4px;
    flex-direction: column;
    display: none;
    font-size: 1.125rem;
    color: #4a4a4a;
}

#input .control .dropzone .icon,
#input .control .loading .icon {
    height: 5rem;
    width: 5rem;
    margin-bottom: 1rem;
}

#input .control .dropzone .icon svg,
#input .control .loading .icon svg {
    font-size: 5rem;
}

#input .control .loading .progress {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px 4px 0 0;
    height: 6px;
    background-color: transparent;
    margin-bottom: 0;
}

#input .control .loading .icon {
    color: #FF9E80;
}

/* Buttons with Addons */
.buttons.has-addons.is-vertical .button:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
}

.buttons.has-addons.is-vertical .button:not(:first-child).is-small {
    border-bottom-left-radius: 2px;
}

.buttons.has-addons.is-vertical .button:not(:last-child) {
    margin-right: 0;
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
}

.buttons.has-addons.is-vertical .button:not(:last-child).is-small {
    border-top-right-radius: 2px;
}

/* Custom Styles */
body>div {
    padding: 0 !important;
}

#input .buttons.is-vertical {
    flex-direction: column;
    align-items: flex-start;
}

#input .buttons.is-vertical .button {
    margin-bottom: 0;
}

#input .buttons.is-vertical:last-child {
    margin-bottom: 0;
}

.buttons.is-vertical {
    flex-direction: column;
    align-items: flex-start;
}

.buttons.is-vertical .button {
    margin-bottom: 0;
}

.buttons.is-vertical:last-child {
    margin-bottom: 0;
}

.editor {
    display: inline-flex;
    gap: 10px;
    font-family: monospace;
    line-height: 21px;
    background: #282a3a;
    border-radius: 2px;
    padding: 20px 10px;
    height: 500px;
    overflow-y: auto;
}

.editor.label {
    display: inline-flex;
    font-family: monospace;
    line-height: 21px;
    background: none;
    border-radius: none;
    padding: 5px 10px;
    height: auto;
    overflow-y: auto;
}

.line-numbers {
    width: auto !important;
    text-align: right;
    height: 100%;
}

.line-numbers span {
    counter-increment: linenumber;
}

.line-numbers span::before {
    content: counter(linenumber);
    display: block;
    color: #506882;
}

textarea  {
    height: auto;
    line-height: 21px;
    overflow-y: hidden;
    padding: 0;
    border: 0;
    background: #282a3a;
    color: #FFF;
    outline: none;
    resize: none;
    overflow-y: scroll;
}

/* Button Names */
.button[name=view-fix-info] {
    color: hsla(0, 0%, 100%, .5);
    width: 1.375rem;
    position: relative;
    top: 1px;
}

.button[name=view-fix-info] .icon {
    font-size: .85rem;
    margin-top: .125rem;
}

.button[name=view-result-error] {
    color: #FF7B52 !important;
}

.button[name=rename-result]:after {
    content: "";
    position: absolute;
    height: 1px;
    display: block;
    border-bottom: 1px dotted #666;
    left: 0;
    bottom: 3px;
    right: 0;
}

.button[name=collapse-node] {
    padding: 0 .75rem !important;
    vertical-align: -.15em;
}

.button[name=collapse-node] .icon {
    height: 1em;
    width: 1em;
}

.button[name=collapse-all-nodes] .icon svg:last-child,
.button[name=collapse-node] .icon svg:last-child,
.button[name=collapse-result] .icon svg:last-child,
.button[name=fullscreen-result] .icon svg:last-child {
    display: none;
}

.results .media {
    padding: 1.25rem 0;
    border-bottom: 1px solid #111;
}

.results .result {
    padding: 20px;
    border-top: 1px solid #333;
    border-bottom: 1px solid #111;
}

.results .messages {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid transparent;
    padding: calc(.75em - 1px);
    max-height: 10em;
    overflow-x: auto;
    overflow-y: scroll;
}

.results .messages .message {
    white-space: nowrap;
}

.results .messages .message .icon {
    color: #FF9E80;
}

.results .messages .message strong {
    width: 5.6em;
    display: inline-block;
}

.results .messages .message em {
    font-size: .8em;
    color: #999;
}

.results .messages .message.is-error .icon {
    color: #c0392b;
}

.results .messages .message.is-warning .icon {
    color: #f1c40f;
}

.section.results .result .container {
    max-width: 944px;
}

.section.results .result .container .columns {
    flex-wrap: wrap;
    justify-content: space-between;
    margin: -.5rem -.5rem 0;
}

@media print,
screen and (min-width:769px) {
    .section.results .result .container .columns {
        flex-wrap: nowrap;
    }
}

.section.results .result .container .columns .column {
    padding: .5rem .5rem 0;
}

.section.results .result .container .columns .column:nth-child(2) {
    order: 10;
}

@media print,
screen and (min-width:769px) {
    .section.results .result .container .columns .column:nth-child(2) {
        order: 0;
        overflow: hidden;
    }
}

body>div {
    padding: 0 !important;
}

/* Input Specific Styles */
#input .control .dropzone,
#input .control .loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background-color: hsla(0, 0%, 99.6%, .9);
    border-radius: 4px;
    flex-direction: column;
    display: none;
    font-size: 1.125rem;
    color: #4a4a4a;
}

#input .control .dropzone .icon,
#input .control .loading .icon {
    height: 5rem;
    width: 5rem;
    margin-bottom: 1rem;
}

#input .control .dropzone .icon svg,
#input .control .loading .icon svg {
    font-size: 5rem;
}

#input .control .loading .progress {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px 4px 0 0;
    height: 6px;
    background-color: transparent;
    margin-bottom: 0;
}

#input .control .loading .icon {
    color: #FF9E80;
}
